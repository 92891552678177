import { CurrencyISOCode } from '@dougs/core/config-back';
import { SalesInvoiceOperationAttachment, SalesInvoiceOperationMatch } from './sales-invoice-operation-attachment.dto';

export interface SalesInvoice extends SalesInvoiceDisplay {
  id: string;
  fileId: number;
  fileName: string;
  filePath: string;
  fileType: string;
  ownerId: string;
  source: SalesInvoiceSource;
  number: string;
  vatAmount: number;
  paidAt: Date | null;
  currency: CurrencyISOCode;
  createdAt: Date;
  updatedAt: Date;
  sourceDocumentId: number;
}

export interface SalesInvoiceDraft extends SalesInvoiceDisplay {
  createdAt: Date | null;
  updatedAt: Date | null;
  finalizedAt: Date | null;
  invoicerName: string | null;
  invoicerOthers: string[];
  id: string;
  number: number | null;
  numberPrefix: string | null;
  subject: string | null;
  paidAt: string | null;
  logoUuid: string | null;
  pdfFileId: number | null;
  invoiceInfoOthers: string[];
  salesInvoiceId: string | null;
  lines: InvoiceLine[];
  discount: number;
  discountUnit: SalesInvoiceDraftDiscountUnit;
  depositAmount: number;
  vatSummary?: VatSummary[];
  totalAmount?: number;
  totalDiscountAmount?: number;
  totalNetAmount?: number;
  totalVatAmount?: number;
  totalAmountWithVat?: number;

  // Footer and other data
  metadata: Record<string, string | boolean>;
  clientData: ClientData;
  thankYouNote: string | null;
  legalData: LegalData;
  footerData: FooterData;
}

export interface SalesInvoiceDisplay {
  label: string;
  date: Date | string;
  clientName: string;
  reference: string;
  amount: number;
  netAmount: string;
  isDraft: boolean;
  paymentStatus?: SalesInvoicePaymentStatus;
  companyId?: number;
  invoicerId: string;
  operationAttachments?: SalesInvoiceOperationAttachment[];
  operationCandidate?: SalesInvoiceOperationMatch;
  isRefund?: boolean;
}

export interface VatSummary {
  amount: number;
  vatAmount: number;
  vatRate: number;
}

export interface InvoiceLine {
  amount: number;
  description: string;
  discount: number;
  discountInEuros: number;
  discountUnit: string;
  quantity: number;
  reference: string;
  title: string;
  unit: string;
  unitAmount: number;
  vatRate: number;
  isPriceWithVat?: boolean;
}

export interface ClientData {
  isBToB: boolean;
  address: ClientAddress;
  deliveryAddress: ClientAddress | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  legalName: string | null;
  others: string[];
  phone: string | null;
  siren: string;
  vatNumber: string | null;
  siret: string;
}

export interface ClientAddress {
  city: string;
  street: string;
  country: string;
  zipCode: string;
}

export interface FooterData {
  legalInformation: string;
  isGeneratedWithCompleteData: boolean;
  missingFields: string;
  contactInformation: string | null;
  liabilityInsurance: Insurance | null;
  tenYearInsurance: Insurance | null;
  others: string[];
}

export interface LegalData {
  iban: string | null;
  isApprovedManagementOrganisation: boolean;
  others: string[];
  paymentTerms: string;
  latePaymentTerms: string | null;
  vatTerms: string | null;
}

export interface Insurance {
  name: string;
  coverage: string;
}

export enum SalesInvoicePaymentStatus {
  WAITING = 'waiting',
  LATE = 'late',
  PAID = 'paid',
}

export enum SalesInvoiceType {
  DRAFT = 'draft',
  FINALIZED = 'finalized',
}

export enum SalesInvoiceDraftDiscountUnit {
  EUR = 'eur',
  PERCENTAGE = '%',
}

export enum SalesInvoiceSource {
  DOUGS = 'dougs',
}
